/**
 * 与交易有关的接口
 * 2021-11-21
 * author DuZeFang
 */
import request from '@/utils/request'

export default class CostApi {
    // 列表
    static list(params) {
        return request({
            url: '/cost/list',
            method: 'post',
            data: params
        })
    }
}