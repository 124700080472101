/**
 * 关于课程方面的接口
 * 2021-11-21
 * author DuZeFang
 */
import request from '@/utils/request'

export default class CourseApi {
    // 课程列表
    static index(params) {
        return request({
            url: '/course/index',
            method: 'post',
			data: params
        })
    }

    // 预约课程详情
    static info(id, params) {
        return request({
            url: `/course/info/${id}`,
            method: 'post',
			data: params
        })
    }

    // 预约课程包详情
    static package(id, params) {
        return request({
            url: `/course/package/${id}`,
            method: 'post',
			data: params
        })
    }

    // 预约课程老师确认
    static verify(params) {
        return request({
            url: '/course/verify',
            method: 'post',
			data: params
        })
    }

    // 取消课程
    static cancel(id, params) {
        return request({
            url: `/course/cancel/${id}`,
            method: 'post',
			data: params
        })
    }

    // 关闭课程
    static close(id, params) {
        return request({
            url: `/course/close/${id}`,
            method: 'post',
			data: params
        })
    }

    // 课后评论
    static appraise(params) {
        return request({
            url: '/course/appraise',
            method: 'post',
			data: params
        })
    }

    // 课后包支付
    static pay(id, params) {
        return request({
            url: `/course/pay/${id}`,
            method: 'post',
			data: params
        })
    }

    // 预约课程
    static order(params) {
        return request({
            url: '/course/order',
            method: 'post',
			data: params
        })
    }

    // 修改预约课程时间
    static updateTime(params) {
        return request({
            url: '/course/updateTime',
            method: 'post',
			data: params
        })
    }

    // 老师的待支付课程
    static costPending(params) {
        return request({
            url: '/course/cost',
            method: 'post',
            data: params
        })
    }

    // 老师的待支付课程
    static orderTrade(params) {
        return request({
            url: '/course/orderTrade',
            method: 'post',
            data: params
        })
    }

    // 设置不添加家庭作业
    static notHomeWork(params) {
        return request({
            url: '/course/notHomeWork',
            method: 'post',
            data: params
        })
    }
}