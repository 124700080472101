<template>
	<div id="tu-cost">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu />
			</el-col>
			<el-col :span="20">
				<div class="warp">
					<div class="desc-style">
						<div v-if="userType==2">
							{{ $t('cost.my') }}
							<div class="desc">{{ $t('cost.note') }}</div>
						</div>
						<div v-else>
							我的支出
							<div class="desc">Please note: 这些是我的课程支出费用</div>
						</div>
						<div></div>
					</div>
					<div class="earning" v-if="userType == 2 && userInfo">
						<div>{{ $t('cost.total') }}:</div>
						<div>{{ userInfo.currency }}{{ userInfo.income_fee || '0.00' }}</div>
					</div>
				</div>
				<div class="tu-title" v-if="userType==2">
					<div>{{ $t('cost.upcoming') }}</div>
					<div></div>
				</div>
				<div v-if="userType==2">
					<el-table 
					v-loading="cloading"
					element-loading-text="Loading..."
					:data="courseData" 
					border 
					:show-header="showHeader">
				    <el-table-column prop="date" :label="$t('cost.date')">
							<template #default="scope">
								<div>{{ handleDateShow(scope.row.book_time) }}</div>
							</template>
				    </el-table-column>
				    <el-table-column prop="account" :label="$t('cost.account')">
				    	<template #default="scope">
								<div>--</div>
							</template>
				    </el-table-column>
				    <el-table-column prop="fee" :label="$t('cost.fee')">
				    	<template #default="scope">
								<div>{{ scope.row.currency }}{{ scope.row.income_fee }}</div>
							</template>
				    </el-table-column>
				    <el-table-column prop="status" :label="$t('cost.status')">
				    	<template #default="scope">
								<div>{{ $t('cost.pending') }}</div>
							</template>
				    </el-table-column>
				    <el-table-column prop="pay_time" :label="$t('cost.pay_time')">
				    	<template #default="scope">
								<div>--</div>
							</template>
				    </el-table-column>
					</el-table>	
				</div>
				<div class="tu-title">
					<div v-if="userType==2">{{ $t('cost.received') }}</div>
					<div v-else>课程支出</div>
					<div></div>
				</div>
				<div>
					<el-table 
					v-loading="loading"
					element-loading-text="Loading..."
					:data="tableData" 
					border 
					:show-header="showHeader">
				    <el-table-column prop="date" :label="$t('cost.date')">
							<template #default="scope">
								<div>{{ handleDateShow(scope.row.book_time) }}</div>
							</template>
				    </el-table-column>
				    <el-table-column prop="account" :label="$t('cost.account')" v-if="userType==2">
				    	<template #default="scope">
								<div>{{ scope.row.account.in_an }}</div>
								<div>{{ scope.row.account.in_sc }}</div>
							</template>
				    </el-table-column>
				    <el-table-column prop="account" label="老师信息" v-else>
				    	<template #default="scope">
								<div>{{ scope.row.user.nickname }}</div>
							</template>
				    </el-table-column>
				    <el-table-column prop="fee" :label="$t('cost.fee')">
				    	<template #default="scope">
								<div>{{ scope.row.currency }}{{ scope.row.fee }}</div>
							</template>
				    </el-table-column>
				    <el-table-column prop="status" :label="$t('cost.status')">
				    	<template #default="scope">
								<div v-if="scope.row.status == 1">{{ $t('cost.paid') }}</div>
								<div v-else-if="scope.row.status == 2">{{ $t('cost.to-be-confirmed') }}</div>
								<div v-else-if="scope.row.status == 3">{{ $t('cost.refunded') }}</div>
							</template>
				    </el-table-column>
				    <el-table-column prop="pay_time" :label="$t('cost.pay_time')">
				    	<template #default="scope">
								<div>{{ handleDateShow(scope.row.pay_time) }}</div>
							</template>
				    </el-table-column>
					</el-table>	
					<div class="tu-pagination">
						<el-pagination 
							:hide-on-single-page="true" 
							background 
							layout="prev, pager, next" 
							:total="params.total"
							:page-size="20"
							@current-change="handlePageChange">
						</el-pagination>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SecondMenu from '@/components/SecondMenu'
import TimeControl from '@/utils/time-control'
import CourseApi from '@/api/course'
import CostApi from '@/api/cost'

export default {
	name: "cost",
	components: {
		SecondMenu
	},
	data() {
		return {
			cloading: true,
			loading: true,
			showHeader: true,
			courseData: [],
			tableData: [],
			params: {
				page: 1,
				total: 0
			}
		}
	},
	created() {
		this.getApiCourseData();
		this.getApiListData();
		this.$store.dispatch('GetUserInfo');
	},
	computed: {
		...mapGetters([
			'userInfo', 'userType'
		])
	},
	methods: {
		handleDetail(index, row) {
			let course_id = row.course_id;
			this.$router.push({ path: `/course/${course_id}` })
		},
		handlePageChange(page) {
			this.loading = true
			this.params.page = page || 1;
			this.getApiListData();
		},
		handleDateShow(value) {
			return TimeControl.getFormatTime(value);
		},
		getApiCourseData() {
			CourseApi.costPending().then(res => {
				this.cloading = false
				this.courseData = res.data;
			});
		},
		getApiListData() {
			CostApi.list().then((res) => {
				this.loading = false
				this.tableData = res.data
				this.params.total = res.meta.pagination.total
			});
		}
	}
}
</script>
<style lang="less">
#tu-cost {
	.tu-title {
		background: var(--el-color-primary);
		padding: 17px 15px;
		color: var(--el-color-white);
		text-align: left;
		font-weight: 700;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	.tu-pagination {
		padding: 20px 0;
	}
	.warp {
		border: 1px solid #CCC;
	  padding: 10px 15px;
	  margin-right: 2px;

	  .desc-style {
	  	display: flex;
	  	justify-content: space-between;
	  	padding: 12px 0;

	  	& > div:first-child {
	  		color: var(--el-color-primary);
	  		font-size: 21px;
	  	}

	  	.desc {
				font-size: 14px;
				color: var(--el-color-info);
				margin: 5px 0;
	  	}
	  }

	  .earning {
	  	text-align: right;
	  	div:first-child {
	  		color: var(--el-color-info);
	  	}
	  	div:last-child {
	  		font-size: 24px;
		    font-weight: 800;
		    margin: 5px 0;
	  	}
	  }

	  .btn {
	  	justify-content: flex-end;
	  }
	}
} 
</style>